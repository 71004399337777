import _ from '../../../utils/lodash';
import styled, { css } from 'styled-components';

import Typed from 'react-typed';

export const Wrapper = styled.div`
    ${({
		theme
	}) => css`
		color: #fff;
		font-family: ${_.get(theme, 'font.family.montserrat', 'Montserrat')};
	`}

	display: flex;
	flex-direction: column;

	padding: 12px 100px;
	padding-top: 168px;

	position: relative;

	& > img {
		position: absolute;
		top: -105px;
		right: calc(100vw - 1920px);
		z-index: 1;
		width: 1237px;
		height: 1297px;
	}

	@media(min-width: 1921px) {
		& > img {
			width: 1045px;	
			height: 1094px;
			top: -89px;
    		right: 0;
		}
	}

	@media(max-width: 1280px) {
		padding: 0px 100px;
		padding-top: 144px;

		& > img {
			width: 1045px;	
			height: 1094px;
			top: -89px;
    		right: calc(100vw - 1428px);
		}
	}

	@media(max-width: 990px) {
		padding: 12px 24px;
		padding-top: 144px;
				
		& > img {
			width:755px;
			height: 792px;
			top: -29px;
    		right: calc(100vw - 1089px);
		}
	}

	@media(max-width: 768px) {
		padding: 0px 16px;
		padding-top: 71px;

		align-items: center;

		& > img {
			margin-top: -64px;
			width: 768px;
			height: 805px;
			position: static;
		}
	}

	@media(max-width: 480px) {
		align-items: center;

		& > img {
			margin-top: -58px;
			height: 636px;
			width: 606px;
			position: static;
		}
	}

	@media(max-width: 452px) {
		& > img {
			margin-top: 0px;
			height: 502px;
			width: 478px;
		}
	}
`;

export const Title = styled.div`
	${({
		theme
	}) => css`
		font-size: 18px;
	`}
	
	line-height: 36px;
	z-index: 5;
`;

export const Subtitle = styled.div`
	${({
		theme
	}) => css`
		font-size: 40px;
		font-family: ${_.get(theme, 'font.family.oswald', 'Oswald')};
	`}

	display: flex;
	align-items: center;

	height: 84px;
    z-index: 5;
	margin-top: 72px;

	position: relative;

	line-height: 108px;

	@media(max-width: 1280px) {
		margin-top: 48px;
		font-size: 40px;
	}

	@media(max-width: 960px) {
		margin-top: 48px;
		font-size: 40px;
	}

	@media(max-width: 768px) {
		font-size: 40px;
		margin-top: 48px;
	}

	@media(max-width: 530px) {
		font-size: 32px;
		margin-top: 48px;
		height: 72px;
	}

	@media(max-width: 452px) {
		font-size: 26px;
	}

	@media(max-width: 350px) {
		font-size: 20px;
	}
`;

export const TypedSubtitle = styled(Typed)`
	${({
		theme
	}) => css`
		/* font-size: ${_.get(theme, 'font.size.h1', '120')}px; */
		font-size: 120px;
		font-family: ${_.get(theme, 'font.family.oswald', 'Oswald')};
	`}

	display: flex;
	height: 156px;
	align-items: center;

	z-index: 5;

	text-transform: uppercase;
	line-height: 108px;
	margin-top: 30px;

	@media(max-width: 1280px) {
		margin-top: -21px;
		font-size: 120px;
	}
	
	@media(max-width: 960px) {
		font-size: 98px;
		height: 120px;
		margin-top: -7px;
	}

	@media(max-width: 768px) {
		font-size: 78px;
		height: 120px;
		margin-top: -17px;
	}

	@media(max-width: 552px) {
		font-size: 50px;
		height: 72px;
		margin-top: -17px;
	}
`;

export const TypedDescription = styled(Typed)`
	${({
		theme
	}) => css`
		font-size: ${_.get(theme, 'font.size.h3', '46')}px;
    	text-shadow: 0px 1px 17px ${_.get(theme, 'colors.yellow', '#FBFF4A')};
	`}

	margin-top: 36px;
	margin-bottom: 36px;
	z-index: 5;

	line-height: 60px;

	@media(max-width: 1280px) {
		margin-top: 12px;
		margin-bottom: 24px;
	}

	@media(max-width: 960px) {
		margin-top: 24px;
		margin-bottom: 24px;
	}

	@media(max-width: 768px) {
		margin-top: 24px;
		margin-bottom: 24px;
	}

	@media(max-width: 560px) {
		font-size: 35px;
		margin-top: 24px;
		margin-bottom: 12px;
	}

	@media(max-width: 479px) {
		font-size: 27px;
	}
`;

export const Description = styled.div`
	${({
		theme
	}) => css`
		font-size: ${_.get(theme, 'font.size.lg', '18')}px;
	`}

	height: 60px;	
    max-width: 631px;
	z-index: 5;

	line-height: 30px;

	@media(max-width: 1280px) {
		max-width: 399px;
		height: 96px;
	}

	@media(max-width: 960px) {
		max-width: 399px;
		height: 96px;
	}

	@media(max-width: 768px) {
		max-width: 631px;
		height: 60px;
		text-align: center;
	}

	@media(max-width: 480px) {
		max-width: 448px;
		height: 96px;
		text-align: center;
	}
`;

export const Icons = styled.a`
	position: relative;
	top: 146px;

	margin: 0 auto;
	width: 40px;

	cursor: pointer;
	
	& > div {
		position: relative; 

		z-index: 11;
		height: 90px;

		& > div {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			width: 100%;
			height: 100%;
			z-index: 11;
			padding: 20px;

			position: absolute;
			top: 0;
			opacity: 1;
			transition: all 700ms ease;

			& > img {
				width: 20px;
				height: 20px;
				z-index: 11;
				transition: all 700ms ease;

				:first-child {
					width: 20px;
					height: 24px;
					margin-bottom: 20px;
				}

				:last-child {
					width: 14px;
    				height: 16px;
				}
			}
		}
	}

	& > div > img {
		position: absolute;
		top: 12px;
		left: 8px;
		opacity: 0;
        transition: all 700ms ease;
	}

	&:hover {
        & > div > div > img {
            opacity: 0;
        }
		
        & > div > img {
			opacity: 1;
			filter: blur(0) drop-shadow(-1px 3px 4px yellow);
        }
    }
`;

