import React from 'react';

import { Wrapper, Image, CardInfo, Title, Icon } from './CardCity.styles';
import { CardCityProps } from './CardCity.types';

export const CardCity: React.FC<CardCityProps> = props => {
	const { image, city } = props;

	return (
		<Wrapper>
			<Image src={image} />

			<CardInfo
				filter={'blur(8px)'}
			>
				<Title>
					<div />
					<Icon>
						<img src={'./icons/ico_location.svg'} alt="Pasta" />
					</Icon>
					<p>{city}</p>
				</Title>
			</CardInfo>
		</Wrapper >
	);
};
