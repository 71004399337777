import _ from '../../../utils/lodash';
import styled, { css} from 'styled-components';

export const Wrapper = styled.div`
    ${({
        theme
    }) => css`
    	color: ${_.get(theme, 'colors.cyan', '#68E1D5')};
        font-family: ${_.get(theme, 'font.family.montserrat', 'Montserrat')};
	`}

    display: flex;
	flex-direction: column;
    align-items: center;
    
    padding-top: 240px;

    & > div:first-child > div {
        margin-bottom: 0;
    }

    & > div {
        width: 100vw;
    }

    @media(max-width: 480px) {
        padding-top: 120px;
    }

    @media(max-width: 452px) {
        & > div:first-child {
            padding: 0 16px;
        }
    }
`;

