import _ from '../../utils/lodash';
import styled, { css } from 'styled-components';

import BackdropFilter from 'react-backdrop-filter';

export const Wrapper = styled(BackdropFilter)`
    ${({
		theme
	}) => css`
		font-family: ${_.get(theme, 'font.family.montserrat', 'Montserrat')};
	`}

	display: flex;
	justify-content: space-between;
	align-items: center;
	overflow: visible;
	
	padding: 12px 100px;

	position: fixed;
	top: 0;
	right: 0px;
    width: 100%;
    z-index: 15;

	& div > img {
		margin-right: 30px;
		
		:hover {
            filter: blur(0) drop-shadow(-1px 3px 4px #FBFF4A);
        }
	}

	@media(max-width: 990px) {
		padding: 12px 24px;
		padding-right: 50px;
		right: 0px;
	}

	@media(max-width: 890px) {
		padding: 18px 16px;
	}

	@media(max-width: 684px) {
		padding: 18px 35px;
	}
`;

export const IconMenuHamburguer = styled.div``;

export const Logo = styled.div``;

export const List = styled.ul`
	display: flex;
	list-style: none;
	align-items: center;
	margin-left: auto;
	z-index: 10;
`;

export const ListItem = styled.li`
    ${({
	theme
}) => css`
		font-size: 14px;

		a {
			color: #fff;
			text-decoration: none;

			:hover {
				text-shadow: 0px 0px 15px ${_.get(theme, 'colors.yellow', '#FBFF4A')};
			}
		}
	`}
		
	font-weight: bold;
	line-height: 14.63px;
	text-transform: uppercase;
	margin-right: 50px;
	white-space: nowrap;
	list-style: none;
`;

export const ButtonLarge = styled.div`
    ${({
	theme
}) => css`
		a { 
			font-size: 14px;
			color: #fff;
			border: 1px solid #fff;
			text-decoration: none;
			font-weight: bold;

			display: flex;
			align-items: center;
			text-transform: uppercase;
			padding: 12px 18px;
			border-radius: 5px;
			background-color: transparent;

			:hover {
				box-shadow: 0px 0px 6px 1px #FBFF4A;
				border: 1px solid ${_.get(theme, 'colors.yellow', '#FBFF4A')};
				text-shadow: 0px 0px 15px ${_.get(theme, 'colors.yellow', '#FBFF4A')};

				& svg {
					filter: blur(0) drop-shadow(-1px 3px 4px #FBFF4A);
				}
        	}

			svg {
				margin-right: 12px;
				width: 20px;
				height: 20px;
			}
		}
	`}
`;

export const ButtonSmall = styled.div`
    ${({
	theme
}) => css`
		a { 
			font-size: ${_.get(theme, 'font.family.size.xs', '12')}px;
			text-decoration: none;
			font-weight: bold;

			display: flex;
			align-items: center;
			text-transform: uppercase;
			border-radius: 5px;
			background-color: transparent;
			margin-left: 11px;

			:hover {
				text-shadow: 0px 0px 15px ${_.get(theme, 'colors.yellow', '#FBFF4A')};

				& svg {
					filter: blur(0) drop-shadow(-1px 3px 4px #FBFF4A);
				}
        	}

			svg {
				width: 24px;
				height: 24px;
			}
		}
	`}

	@media(max-width: 685px) {
		a > svg {
			width: 36px;
			height: 36px;
		}
	}
`;

export const ButtonChangeLanguage = styled.button`
    ${({
		language
	}) => css`
		& > img:first-child {
			display: ${language === 'pt-BR' || language ===  'pt-br' ? 'block' : 'none' };
		}


		& > img:last-child {
			display: ${language === 'pt-BR' || language ===  'pt-br' ? 'none' : 'block' };
		}
	`}

	background: none;
	border: none;
	margin-left: 50px;
	cursor: pointer;
`;