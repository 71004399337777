import React from 'react';

import { Wrapper, Image, CardInfo, Info, Title, Icon, Description, Subtitle } from './CardAbout.styles';
import { CardAboutProps } from './CardAbout.types';

export const CardAbout: React.FC<CardAboutProps> = props => {
	const { image, title, description, subtitle, icon } = props;

	return (
		<Wrapper>
			<Image src={image} />

			<CardInfo
				filter={'blur(8px)'}
			>
				<Title>
					<div />
					<Icon>
						{
							icon === 'folder'
							? <img src={'./icons/ico_projetos.svg'} alt="Pasta" />
							: icon === 'lightning'
							? <img src={'./icons/ico_aceleracao.svg'} alt="Raio" />
							: icon === 'alocation'
							? <img src={'./icons/ico_alocacao.svg'} alt="Alocação" />
							: icon === 'courses'
							? <img src={'./icons/ico_cursos.svg'} alt="Cursos" />
							: icon === 'handshaking'
							? <img src={'./icons/ico_consultoria.svg'} alt="Consultoria" />
							: icon === 'recruiting'
							? <img src={'./icons/ico_recrutamento.svg'} alt="Recrutamento" />
							: <img src={'./icons/ico_projetos.svg'} alt="Pasta"  />
						}
					</Icon>
					<p>{title}</p>
				</Title>
				<Info>
					<Description>{description}</Description>
					<Subtitle>{subtitle}</Subtitle>
				</Info>
			</CardInfo>
		</Wrapper >
	);
};
