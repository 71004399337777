import _ from '../../../utils/lodash';
import styled, { css } from 'styled-components';

import { Accordion, AccordionSummary } from '@mui/material';

export const Wrapper = styled.div`
    ${({
        theme
    }) => css`
    	color: #fff;
        font-family: ${_.get(theme, 'font.family.montserrat', 'Montserrat')};
	`}

    display: flex;
	flex-direction: column;
	padding: 18px 24px;
    padding-top: 240px;
    position: relative;

    & > img {
        position: absolute;
        top: 340px;
        right: 60px;
        z-index: 1;
        width: 835px;
        height: 834px;
    }

    @media(max-width: 1280px) {
        padding-top: 168px;
    }

    @media(max-width: 768px) {
        padding: 0px 16px;
        padding-top: 180px;
        margin-top: -205px;
	}

    @media(max-width: 480px) {
        padding: 0px 16px;
        padding-top: 180px;
        margin-top: -195px;
	}
`;

export const Cards = styled.div`
    margin-top: 120px;
    z-index: 3;
    display: flex;
    flex-direction: column;

    & > div {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 300px);
        grid-gap: 18px;
    }

    & > div:nth-child(2) {
        margin-top: 18px;
        /* display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: 300px;
        grid-gap: 18px; */
    }

    & > div:nth-child(3) {
        margin-top: 18px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 300px;
        grid-gap: 18px;
    }

    @media(max-width: 960px) {
        /* & > div:nth-child(2) {
            margin-top: 18px;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, 264px);
        } */
	} 
`;

export const Link = styled.a`
    ${({
        theme
    }) => css`
        color: #fff;
        
        & > p {
            font-size: ${_.get(theme, 'font.size.md', '16')}px;
        }
    `}

    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
    margin: 120px auto;
    text-decoration: none;

    & > p {
        text-align: center;
        line-height: 24px;
        font-weight: bold;
    }

    & > div {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        & > img > path {
            width: 24px;
            height: 24px;;
        }
    
        & > img:last-child {
            position: absolute;
            top: 20px;
            left: -10px;
            opacity: 0;
            transition: all 700ms ease;
        }

        & > img:first-child {
            position: absolute;
            top: 24px;
            opacity: 1;
            transition: all 700ms ease;
        }
    }

    &:hover {
        & div > img:last-child {
            opacity: 1;
            filter: blur(0) drop-shadow(-1px 3px 4px yellow);
        }

        & div > img:first-child {
            opacity: 0;
        }
    }
`;

export const Accordions = styled.div`
    margin-top: 120px;

    & > div:nth-child(2) {
        margin-top: 24px;
        margin-bottom: 24px;
    }

    & > div:nth-child(3) {
        margin-top: 24px;
        margin-bottom: 24px;
    }

    .MuiAccordion-root {
        background: transparent;
        padding-left: 0;
        padding-right: 0;
        box-shadow: none;

        &:before {
            height: 0;
        }

        .MuiAccordionDetails-root {
            padding-top: 0;
        }
    }
`;

export const CardsAccordion = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 168px);
    grid-gap: 24px;
`;

export const CardsAccordionSystems = styled.div`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(5, 168px);
    grid-gap: 24px;

    @media(max-width: 452px) {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(5, 168px);
    }
`;

export const AccordionStyled = styled(Accordion)`
    .MuiButtonBase-root.MuiAccordionSummary-root {
        ${({ theme, type }) => css`
            color: ${type === 'apps' ?
            _.get(theme, 'colors.yellow', '#FBFF4A') : type === 'sites' ?
                _.get(theme, 'colors.yellow_2', '#FFC267') : type === 'systems' ?
                    _.get(theme, 'colors.pink', '#FC9287') : type === 'design' ?
                        _.get(theme, 'colors.pink_2', '#FC5C4F') :
                        _.get(theme, 'colors.yellow', '#FBFF4A')
            };
        `}

        background: transparent;
        padding-left: 0;
        padding-right: 0;

        span {
            font-size: 20px;
            font-weight: 700;
        }

        .MuiSvgIcon-root {
            ${({ theme, type }) => css`
                color: ${type === 'apps' ?
                _.get(theme, 'colors.yellow', '#FBFF4A') : type === 'sites' ?
                    _.get(theme, 'colors.yellow_light', '#FFC267') : type === 'systems' ?
                        _.get(theme, 'colors.pink', '#FC9287') : type === 'design' ?
                            _.get(theme, 'colors.pink_dark', '#FC5C4F') :
                            _.get(theme, 'colors.yellow', '#FBFF4A')
                };
            `}
        }
    }

    & .MuiAccordionDetails-root {
        padding-left: 0;
        padding-right: 0;
    }
`;

export const AccordionSummaryStyled = styled(AccordionSummary)``;