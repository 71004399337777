import _ from '../../utils/lodash';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    ${({
    	theme,
		color,
	}) => css`
    	color: ${_.get(theme, `colors.${color}`, '#FBFF4A')};
        font-family: ${_.get(theme, 'font.family.montserrat', 'Montserrat')};
	`}

    position: relative;
    z-index: 10;

    padding-left: 30px;
    padding-right: 30px;
`;

export const Title = styled.div`
    ${({
        theme,
        nowrap
    }) => css`
        font-size: ${_.get(theme, 'font.size.h4', '42')}px;
        white-space: ${nowrap && 'nowrap'};
    `}
    
    margin-bottom: 60px;

    line-height: 48px;
    font-weight: bold;
    text-align: center;

    @media(max-width: 452px) {
        font-size: 32px;
    }
`;

export const Subtitle = styled.div`
    ${({
        theme
    }) => css`
        font-size: ${_.get(theme, 'font.size.xg', '20')}px;
    `}

    margin-bottom: 18px;

    line-height: 30px;
    font-weight: bold;
    text-align: center;
`;

export const Description = styled.div`
    ${({
            theme
    }) => css`
        font-size: ${_.get(theme, 'font.size.md', '16')}px;
    `}

    display: flex;
    align-items: center;    

    max-width: 1240px;
    margin: 0 auto;

    text-align: center;
    line-height: 30px;

    @media(max-width: 1280px) {
        max-width: 1024px;
    }

    @media(max-width: 960px) {
        max-width: 912px;
    }

    @media(max-width: 768px) {
        max-width: 736px;
    }

    @media(max-width: 480px) {
        max-width: 448px;
    }
`;
