import _ from '../../utils/lodash';
import styled, { css } from 'styled-components';

import BackdropFilter from 'react-backdrop-filter';

export const Wrapper = styled.div`
    ${({
        theme,
	}) => css`
	    color: ${_.get(theme, 'colors.cyan', '#68E1D5')};
        font-family: ${_.get(theme, 'font.family.montserrat', 'Montserrat')};
	`}

    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;

    height: 683px;
    border-radius: 5px;

    margin-bottom: 50px;
`;

export const Image = styled.img`
    height: 384px;
    width: 608px;
    border-radius: 18px;
    object-fit: cover;

    @media (max-width: 1855px) {
        height: 300px;
        width: 399px;
    }

    @media (max-width: 1250px) {
        height: 216px;
        width: 288px;
    }
    @media (max-width: 627px) {
        height: 320px;
        width: 500px;
    }

    @media (max-width: 550px) {
        height: 283px;
        width: 400px;
    }

    @media (max-width: 435px) {
        height: 208px;
        width: 329px;
    }

    @media (max-width: 360px) {
        height: 209px;
        width: 322px;
    }
`;

export const CardInfo = styled(BackdropFilter)`
    ${({
        theme
	}) => css`
		font-size: ${_.get(theme, 'font.size.sm', '14')}px;
        background-color: ${_.get(theme, 'colors.blue_dark', '#161A32CC')};
    `}

    display: flex;
    flex-direction: column;

    height: 456px;
    width: 450px;
    padding: 36px 55px;

    position: absolute;
    top: 295px;

    backdrop-filter: blur(20px); 
    border-radius: 10px;
    line-height: 24px;

    @media (max-width: 1855px) {
        height: 528px;
        width: 331px;
        top: 252px;
        padding: 36px 37px;
    }

    @media (max-width: 1250px) {
        height: 588px;
        width: 248px;
        top: 153px;
        padding: 36px 34px;
    }

    @media (max-width: 925px) {
        height: 690px;
        padding: 36px 14px;
        top: 120px;
    }

    @media (max-width: 751px) {
        height: 688px;
        top: 152px;
    }

    @media (max-width: 627px) {
        height: 458px;
        top: 223px;
        width: 423px;
    }

    @media (max-width: 550px) {
        width: 340px;
        height: 464px;
        top: 199px;
    }

    @media (max-width: 435px) {
        height: 541px;
        width: 281px;
        top: 153px;
        padding: 36px 34px;
    }

    @media (max-width: 360px) {
        height: 601px;
        width: 277px;
        top: 148px;
        padding: 36px 34px;
    }
`;

export const Title = styled.div`
    ${({
        theme
	}) => css`
		font-size: ${_.get(theme, 'font.size.h7', '22')}px;
    `}

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    line-height: 36px;
    font-weight: bold;
    margin-bottom: 24px;

    position: relative;
    
    & svg {
        width: 36px;
        height: 32px;
    }

    & > div:first-child {
        position: absolute;
        right: 42%;
        width: 44px;
        height: 42px;
        border-radius: 410px;
        background: #2AC6B2;
        filter: blur(18px); 
    }

    & > p {
        margin-top: 24px;
    }

    @media(max-width: 452px) {
        font-size: 19px;
    }
`;

export const Icon = styled.div`
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Info = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex: 1;
`;

export const Description = styled.div`
    ${({
        theme
	}) => css`
		font-size: ${_.get(theme, 'font.size.sm', '14')}px;
    `}

    width: 340px;
    line-height: 30px;
    text-align: center;
    
    @media (max-width: 1855px) {
        width: 227px;
    }

    @media (max-width: 1250px) {
        width: 180px;
    }

    @media (max-width: 925px) {
        width: 165px;
    }

    @media (max-width: 627px) {
        width: 350px;
    }

    @media (max-width: 550px) {
        width: 240px;
    }

    @media (max-width: 435px) {
        width: 180px;
    }
`;

export const Subtitle = styled.div`
    ${({
        theme
	}) => css`
		font-size: ${_.get(theme, 'font.size.sm', '14')}px;
    `}

    line-height: 30px;
    font-weight: bold;
    text-align: center;
    margin-top: 24px;
`;




