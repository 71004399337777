import React, { useEffect, useState } from 'react';
import useWindowSize from 'src/utils/hooks/useWindowSize';
import { useTranslation } from 'react-i18next';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/autoplay';

import { CardClient } from '../../CardClient';

import { HeaderSection } from '../../HeaderSection';

import { Wrapper } from './Clients.styles';

export const Clients: React.FC = () => {
	const size = useWindowSize();
	const { t } = useTranslation();

	const [resolution, setResolution] = useState(null);

	const headerClients = {
		title: t('headerClients.title'),
		color: 'cyan',
	};

	const cardsClients = [
		{
			description: t('cardsClients.senac.description'),
			companyLogo: './clients/depoimentos_senac.svg',
			companyName: 'SENAC RS'
		},
		{
			description: t('cardsClients.abl.description'),
			companyLogo: './clients/depoimentos_abl.svg',
			companyName: 'Grupo ABL'
		},
		{
			description: t('cardsClients.newAge.description'),
			companyLogo: './clients/depoimentos_newage.svg',
			companyName: 'New Age'
		},
		{
			description: t('cardsClients.divibank.description'),
			companyLogo: './clients/depoimentos_divibank.svg',
			companyName: 'DIVIBANK'
		},
		{
			description: t('cardsClients.aegea.description'),
			companyLogo: './clients/depoimentos_aegea.svg',
			companyName: 'AEGEA'
		},
		{
			description: t('cardsClients.lit.description'),
			companyLogo: './clients/depoimentos_lit.svg',
			companyName: 'LIT'
		},
		{
			description: t('cardsClients.gvw.description'),
			companyLogo: './clients/depoimentos_gvw.svg',
			companyName: 'GVW'
		},
		{
			description: t('cardsClients.rb.description'),
			companyLogo: './clients/depoimentos_rb.svg',
			companyName: 'RB'
		},
		{
			description: t('cardsClients.badesc.description'),
			companyLogo: './clients/depoimentos_badesc.svg',
			companyName: 'BADESC'
		},
	];

	useEffect(() => {
		setResolution(size.width);
	}, [size]);

	return (
		<Wrapper id="clients">
			<HeaderSection data={headerClients} />

			<Swiper
				modules={[Autoplay]}
				spaceBetween={
					resolution <= 480 ? 24
						: resolution <= 768 ? 18
							: resolution <= 960 ? 24
								: resolution <= 1280 ? 18
									: 24
				}
				slidesPerView={
					resolution <= 480 ? 1
						: resolution <= 768 ? 3
							: resolution <= 960 ? 3
								: resolution <= 1280 ? 5
									: 6
				}
				autoplay={{ delay: 0 }}
				loop
				loopedSlides={6}
				speed={6000}
			>
				{
					cardsClients.map((card, index) => {
						return (
							<SwiperSlide key={index}>
								<CardClient
									description={card.description}
									companyName={card.companyName}
									companyLogo={card.companyLogo}
								/>
							</SwiperSlide>
						);
					})
				}
			</Swiper>
		</Wrapper>
	);
};
