import React from 'react';
import { useTranslation } from 'react-i18next';

import {
	Wrapper,
	Title,
	Subtitle,
	TypedSubtitle,
	TypedDescription,
	Description,
} from './Hero.styles';

export const Hero: React.FC = () => {
	const { t } = useTranslation();

	const subtitlesHero = [
		t('subtitlesHero.quality'),
		t('subtitlesHero.speed'),
		t('subtitlesHero.technology'),
		t('subtitlesHero.precision'),
		t('subtitlesHero.agility'),
		t('subtitlesHero.innovation'),
	];

	const descriptionsHero = [
		t('descriptionsHero.systems'),
		t('descriptionsHero.coffee'),
		t('descriptionsHero.digitalSolutions'),
		t('descriptionsHero.apps'),
		t('descriptionsHero.websites'),
		t('descriptionsHero.landingPages'),
		t('descriptionsHero.portals'),
	];

	return (
		<Wrapper>
			<Title>| {t('TitleHero')}</Title>

			<Subtitle>
				{t('SubtitleHero')}
			</Subtitle>

			<TypedSubtitle
				strings={subtitlesHero}
				typeSpeed={60}
				loop
			/>

			<TypedDescription
				strings={descriptionsHero}
				typeSpeed={60}
				loop
			/>

			<Description>
				{t('DescriptionHeroPart1')}
				<strong>{t('DescriptionHeroPart2')}</strong> 
				{t('DescriptionHeroPart3')}
			</Description>

			<img src={'./images/hero.jpg'}  alt='Astronauta' />
		</Wrapper>
	);
};
