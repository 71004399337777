import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import translationEN from './utils/locales/en/translation.json';
import translationPT from './utils/locales/pt/translation.json';

const resources = {
    pt: {
        translation: translationPT
    },
    en: {
        translation: translationEN
    },
};

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "en", 

    interpolation: {
      escapeValue: false 
    }
  });

export default i18n;