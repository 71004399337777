import React, { useState } from 'react';
import './i18n';

import { GlobalStyle, theme } from './styles';
import { ThemeProvider } from 'styled-components';

import { Header } from './components/Header';
import { MenuHamburguer } from './components/MenuHamburguer';
import { Expertise } from './components/Sections/Expertise';
import { About } from './components/Sections/About';
import { Techs } from './components/Sections/Techs';
import { Hero } from './components/Sections/Hero';
import { Clients } from './components/Sections/Clients';
import { Cases } from './components/Sections/Cases';
import { Form } from './components/Sections/Form';
import { Cities } from './components/Sections/Cities';
import { Footer } from './components/Footer';

import { 
  CircularAboutLeftBlue, 
  CircularAboutLeftCyan, 
  CircularAboutRight, 
  CircularCases, 
  CircularExpertiseLeft, 
  CircularExpertiseRight, 
  CircularForm, 
  CircularHero, 
  CircularTech, 
  Image, 
  Landing 
} from './App.styles';

function App() {
  const [open, setOpen] = useState(false)
	
  return (
    <ThemeProvider theme={theme}>
        <Landing>
          <Header setOpen={setOpen} />
          <MenuHamburguer open={open} setOpen={setOpen}/>

          <main>
            <CircularHero />
            <CircularExpertiseLeft />
            <CircularExpertiseRight />
            <CircularAboutLeftCyan />
            <CircularAboutRight />
            <CircularAboutLeftBlue />
            <CircularTech />
            <CircularCases />
            <CircularForm  />

            <Hero />
            <Expertise />

            <Image>
              <img src={'./images/astro_sobre.jpg'} alt='Astronauta' />
            </Image>

            <About />
            <Techs />
            <Cases />
            <Clients />
            
            <Cities />
          </main>
          <footer>
            <Footer />
          </footer>
        </Landing>
        <GlobalStyle />
    </ThemeProvider>
  );
};

export default App;
