import _ from '../../../utils/lodash';
import styled, { css} from 'styled-components';

export const Wrapper = styled.div`
    ${({
        theme
    }) => css`
    	color: ${_.get(theme, 'colors.orange', '#FF6C2D')};
        font-family: ${_.get(theme, 'font.family.montserrat', 'Montserrat')};
	`}

    display: flex;
	flex-direction: column;

    & > div:nth-child(2) {
        width: 100vw;
    }

    & > div:last-child {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 1;

        position: relative;

        margin-top: 253px;

        & > img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
        }

        & > div:last-child {
            border-radius: 20px 0px 0px 20px;
            align-items: flex-start;

            & div > div {
                margin-right: 99px;
                margin-left: 0px;
            }

            & div > div:last-child {
                margin-right: 0;
            }
        }
    }

    @media(max-width: 1280px) {
        & > div:nth-child(2) {
            margin-left: 15px;
        }

        & > div:last-child > div:last-child {
            padding: 60px 76px 72px 52px;
                
            & div > div {
                margin-right: 18px;
                margin-left: 0px;
            }
        }

        & > div:last-child > img {
            width: 590px;
            height: 900px;
        }
    }

    @media(max-width: 960px) {
        & > div:nth-child(2) {
            margin-left: 15px;
        }

        & > div:last-child  > div:last-child {
            padding: 60px 24px;
                
            & div > div {
                margin-right: 18px;
                margin-left: 0px;
            }
        }

        & > div:last-child > img {
            width: 584px;
            height: 888px;
        }
    }

    @media(max-width: 768px) {
        & > div:last-child > img {
            width: 592px;
            height: 900px;
        }   
    }

    @media(max-width: 480px) {
        & > div:last-child {
            margin-top: 133px;
        }

        & > div:last-child > img {
            width: 480px;
            height: 728px;
        }   
    }

    @media(max-width: 452px) {
        & > div:first-child {
            padding: 0 16px;
        }

        & > div:last-child {
            margin-top: 323px;
        }
        
        & > div:last-child > img {
            top: 11%;    
            width: 411px;
            height: 624px; 
        }
    }
`;

export const Image = styled.img`
    margin-top: 54px;
`;

export const Numbers = styled.div`
    ${({
        theme
    }) => css`
    	background-color: ${_.get(theme, 'colors.orange', '#FF6C2D')};
	`}

    height: 468px;
    width: 632px;

    z-index: 11;

    left: 0px;
    top: 6024px;
    border-radius: 0px 20px 20px 0px;

    display: flex;
    flex-direction: column;
    align-items: flex-end;

    padding: 60px 116px 72px 140px;
    justify-content: space-between;

    & > div:first-child > div {
        margin-bottom: 72px;
    }

    @media(max-width: 1280px) {
        height: 468px;
        width: 423px;
        padding: 60px 52px 72px 76px;
    }

    @media(max-width: 960px) {
        height: 456px;
        width: 336px;
        padding: 60px 24px;
    }

    @media(max-width: 768px) {
        height: 468px;
        width: 267px;
        padding: 60px 18px 72px 18px;

        & > div:first-child > div {
            margin-bottom: 60px;
        }
    }

    @media(max-width: 552px) {
        height: 780px;
        width: 149px;
        padding: 60px 21px;

        & > div:first-child > div {
            margin-bottom: 36px;
        }
    }
`;

export const Row = styled.div` 
    display: flex;

    @media(max-width: 552px) {
        flex-direction: column;
    }
`;

export const Item = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 144px;
    margin-left: 99px;

    @media(max-width: 1280px) {
        margin-left: 18px;
    }   

    @media(max-width: 960px) {
        margin-left: 24px;
    }

    @media(max-width: 768px) {
        margin-left: 18px;
    }

    @media(max-width: 552px) {
        margin-bottom: 36px;
        margin-left: 0;
        height: auto;
    }
`;

export const Icon = styled.img`
    margin-bottom: 22px;
`;

export const Number = styled.p`
    ${({
        theme
    }) => css`
    	color: ${_.get(theme, 'colors.orange_dark', '#FF6C2D')};
        font-size: 28px;
	`}

    line-height: 34.13px;
    font-weight: bold;
    margin-bottom: 12px;

    @media(max-width: 960px) {
        font-size: 24px;
        line-height: 29.26px;
    }

    @media(max-width: 768px) {
        font-size: 24px;
        line-height: 29.26px;
    }

    @media(max-width: 552px) {
        font-size: 24px;
        line-height: 29.26px;
    }
`;

export const Title = styled.p`
    ${({
        theme
    }) => css`
    	color: ${_.get(theme, 'colors.orange_dark', '#FF6C2D')};
        font-size: ${_.get(theme, 'font.size.sm', '14')}px;
	`}

    line-height: 17px;
    width: 138px;
    text-align: center;

    @media(max-width: 768px) {
        font-size: 13px;
        line-height: 15.85px;
        width: 107px;
    }

    @media(max-width: 552px) {
        font-size: 13px;
        line-height: 15.85px;
    }
`;