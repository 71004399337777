import _ from '../../utils/lodash';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    ${({
    theme
}) => css`
    	color: ${_.get(theme, 'colors.yellow', '#FBFF4A')};
        font-family: ${_.get(theme, 'font.family.montserrat', 'Montserrat')};
        font-size: ${_.get(theme, 'font.size.xs', '12')}px;
	`}

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    position: relative;

    line-height: 24px;
    overflow: hidden;
    margin-bottom: 0;
    padding-top: 120px;

    & > div:first-child {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        z-index: 9;

        position: relative;
    }

    @media(max-width: 768px) {
        & > div:first-child > img {
            width: 768px;
            height: 497px;
        }
    }

    @media(max-width: 569px) {
        & > div:first-child > img {
            width: 480px;
            height: 311px;
        }
    }

    @media(max-width: 452px) {
        padding-top: 252px;

        & > div:first-child > p:first-child {
            max-width: 326px;
        }

        & > div:first-child > img {
            width: 480px;
            height: 311px;
        }
    }
`;

export const List = styled.ul`
	display: flex;
	list-style: none;
	align-items: center;
	z-index: 10;
    margin-bottom: 48px;

    & > div {
        display: flex;
    }

    & > div:last-child > li:last-child  {
            margin-right: 0;
    }

    & > li:nth-child(3) {
        width: 46px;
    }

    & > li:last-child {
        margin-right: 0;
    }

    @media(max-width: 452px) {
        & > div {
            flex-direction: column;
            align-items: center;

            & > li {
                margin-top: 24px;
                align-items: center;
                margin-right: 0;
                height: 36px;
            }
        }
    }
`;

export const ListItem = styled.li`
    ${({
	theme
}) => css`
		font-size: ${_.get(theme, 'font.family.size.xs', '12')}px;

		a {
			color: ${_.get(theme, 'colors.yellow', '#FBFF4A')};
			text-decoration: none;

			:hover {
				text-shadow: 0px 0px 15px ${_.get(theme, 'colors.yellow', '#FBFF4A')};
			}
		}
    `}
               
    & > img {
        cursor: pointer;
        width: 46px;
            
        :hover {
            filter: blur(0) drop-shadow(-1px 3px 4px #FBFF4A);
        }
    }

	font-weight: bold;
	line-height: 14.63px;
	text-transform: uppercase;
	margin-right: 80px;
	white-space: nowrap;
	list-style: none;
    width: 74px;
    text-align: center;

    @media (max-width: 700px) {
        margin-right: 24px;
    }

    @media(max-width: 452px) {
        & > img {
            position: absolute;
            top: -39px;
            left: 45%;
        }
    }
`;

export const Buttons = styled.div`
    margin-bottom: 48px;
	justify-content: center;
    display: flex;

	& > button {
		background: none;
    	border: none;
		margin-right: 19px;

		& > a > div {
			border: 1px solid #FBFF4A;
			border-radius: 50%;
			padding: 15px;
            display: flex;
            align-items: center;
	
			& > svg {
				width: 18px;
				height: 18px;
			}
		}
    }

	& > button:last-child {
		margin-right: 0;
	}
`;

export const ButtonInstagram = styled.button`
    :hover {
        svg {
            path {
                fill: #FF3768;
            }
        }

        & > a > div {
		    border: 1px solid #FF3768;
	    }
	}
`;

export const ButtonLinkedin = styled.button`
    :hover {
        svg {
            path {
                fill: #37ABFF;
            }
        }

        & > a > div {
		    border: 1px solid #37ABFF;
	    }
	}
`;

export const ButtonFacebook = styled.button`
    :hover {
        svg {
            path {
                fill: #3757FF;
            }
        }

        & > a > div {
		    border: 1px solid #3757FF;
	    }
	}
`;

export const Address = styled.p`
    margin-bottom: 12px;
	text-align: center;
`;

export const Email = styled.p`
    font-weight: bold;
	text-align: center;
`;

export const Whatsapp = styled.p`
    font-weight: bold;
	text-align: center;
`;