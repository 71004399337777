import React from 'react';
import { useTranslation } from 'react-i18next';

import { CardAbout } from '../../CardAbout';

import { HeaderSection } from '../../HeaderSection';

import { Wrapper, Infos, Cards, Info, Title, Description } from './About.styles';

export const About: React.FC = () => {
	const { t } = useTranslation();

	const headerAbout = {
		title: t('headerAbout.title'),
		subtitle: t('headerAbout.subtitle'),
		description: t('headerAbout.description'),
		color: 'cyan',
		nowrap: true
	};

	const infosAbout = [
		{
			title: t('infosAbout.quality.title'),
			description: t('infosAbout.quality.description'),
		},
		{
			title: t('infosAbout.experience.title'),
			description: t('infosAbout.experience.description'),
		},
		{
			title: t('infosAbout.relationship.title'),
			description: t('infosAbout.relationship.description'),
		}
	];

	const cardsAbout = [
		{
			image: './images/SOBRE_projetos.jpg',
			icon: 'folder',
			title: t('cardsAbout.projects.title'),
			subtitle: t('cardsAbout.projects.subtitle'),
			description: t('cardsAbout.projects.description'),
		},
		{
			image: './images/SOBRE_aceleracao.jpg',
			icon: 'lightning',
			title: t('cardsAbout.acceleration.title'),
			subtitle: t('cardsAbout.acceleration.subtitle'),
			description: t('cardsAbout.acceleration.description'),
		},
		{
			image: './images/SOBRE_alocacao.jpg',
			icon: 'alocation',
			title: t('cardsAbout.allocation.title'),
			subtitle: t('cardsAbout.allocation.subtitle'),
			description: t('cardsAbout.allocation.description'),
		},
		{
			image: './images/SOBRE_cursos.jpg',
			icon: 'courses',
			title: t('cardsAbout.empowerment.title'),
			subtitle: t('cardsAbout.empowerment.subtitle'),
			description: t('cardsAbout.empowerment.description'),
		},
		{
			image: './images/SOBRE_consultoria.jpg',
			icon: 'handshaking',
			title: t('cardsAbout.consulting.title'),
			subtitle: t('cardsAbout.consulting.subtitle'),
			description: t('cardsAbout.consulting.description'),
		},
	];

	return (
		<Wrapper id="about">
			<HeaderSection data={headerAbout} />

			<Infos>
				{
					infosAbout.map((info, index) => {
						return (
							<Info key={index}>
								<Title>{info.title}</Title>
								<Description>{info.description}</Description>
							</Info>
						)
					})
				}
			</Infos>

			<Cards>
				{
					cardsAbout.map((card, index) => {
						return (
							<CardAbout
								key={index}
								image={card.image}
								title={card.title}
								icon={card.icon}
								description={card.description}
								subtitle={card.subtitle}
							/>
						)
					})
				}
			</Cards>
		</Wrapper>
	);
};
