import _ from '../../utils/lodash';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    ${({
        theme,
        width,
        height,
	}) => css`
	    color: ${_.get(theme, 'colors.cyan', '#68E1D5')};
        font-family: ${_.get(theme, 'font.family.montserrat', 'Montserrat')};
        height: ${height};
        width: ${width};      
	`}

    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;

    position: relative;

    height: 428px;
    margin-top: 92px;

    & > div:last-child {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }
`;

export const WrapperImage = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;
    
    height: 123.89px;
    width: 132.57px;
    margin-bottom: 24px;

    & > div {
        position: absolute;
        width: 66px;
        height: 44px;
        border-radius: 410px;
        background: #2AC6B2;
        filter: blur(35px); 
    }
`;

export const Image = styled.img`
    height: 82.02px;
    width: 105.73px;
`;

export const Description = styled.div`
    ${({
        theme
	}) => css`
		font-size: ${_.get(theme, 'font.size.md', '16')}px;
    `}

    font-weight: 500;
    font-style: italic;
    line-height: 32px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;

    max-width: 234px;
    margin-bottom: 24px;

    @media (max-width: 1280px) {
        max-width: 294px;
    }

    @media (max-width: 960px) {
        max-width: 288px;
    }

    @media (max-width: 768px) {
        max-width: 234px;
    }

    @media (max-width: 480px) {
        max-width: 290px;
    }
`;

export const InfoClient = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const Company = styled.div`
    ${({
        theme
	}) => css`
		font-size: ${_.get(theme, 'font.size.sm', '14')}px;
    `}

    line-height: 17.07px;
    font-weight: bold;
`;





