export const theme = {
	font: {
		base: 'lg',
		family: {
			montserrat: 'Montserrat',
			oswald: 'Oswald',
		},
		size: {
			xs: 12,
			sm: 14,
			md: 16,
			lg: 18,
			xg: 20,
			h7: 22,
			h6: 24,
			h5: 32,
			h4: 42,
			h3: 46,
			h2: 80,
			h1: 120,
		},
	},
	colors: {
		cyan: '#68E1D5',
		orange: '#FF6C2D',
		orange_dark: '#662500',
		blue_dark: '#161A32CC',

		yellow: '#FBFF4A;',
		yellow_dark: '#55570F',
		
		yellow_2: '#FFC267',
		yellow_2_dark: '#6B4F26',
		
		pink: '#FC9287',
		pink_dark: '#84514B',

		pink_2: '#FC5C4F',
		pink_2_dark: '#822821',
	},
};
