export const quanticInfo = {
	address: '',
	email: 'contato@quantye.com',
	whatsapp: '+55 51 99806-9824'
};

export const imagesCases = [
	'./cases/case_abl.svg',
	'./cases/case_aegea.svg',
	'./cases/case_badesc.svg',
	'./cases/case_base.svg',
	'./cases/case_braile.svg',
	'./cases/case_carrefour.svg',
	'./cases/case_fiergs.svg',
	'./cases/case_fiesc.svg',
	'./cases/case_finmatch.svg',
	'./cases/case_getnet.svg',
	'./cases/case_gvw.svg',
	'./cases/case_hp.svg',
	'./cases/case_lit.svg',
	'./cases/case_marth.svg',
	'./cases/case_newage.svg',
	'./cases/case_rb.svg',
	'./cases/case_senac.svg',
	'./cases/case_sescon.svg',
	'./cases/case_stefanini.svg',
	'./cases/case_sucesso_simultaneo.svg',
	'./cases/case_tim.svg',
	'./cases/case_divibank.svg',
	'./cases/case_urbanfarmcy.svg',
];

export const imagesTechs = [
	'./techs/adobe.svg',
	'./techs/amazon_web_services.svg',
	'./techs/android.svg',
	'./techs/angular.svg',
	'./techs/atomic_design.svg',
	'./techs/azure.svg',
	'./techs/css5.svg',
	'./techs/docker.svg',
	'./techs/dot_net_core.svg',
	'./techs/figma.svg',
	'./techs/flutter.svg',
	'./techs/google_cloud.svg',
	'./techs/grafana.svg',
	'./techs/html5.svg',
	'./techs/ios.svg',
	'./techs/java.svg',
	'./techs/js5.svg',
	'./techs/kubernetes.svg',
	'./techs/mongodb.svg',
	'./techs/mysql.svg',
	'./techs/node.svg',
	'./techs/openstack.svg',
	'./techs/oracledb.svg',
	'./techs/php.svg',
	'./techs/postgre_sql.svg',
	'./techs/prometheus.svg',
	'./techs/python.svg',
	'./techs/react.svg',
	'./techs/redhat_openshift.svg',
	'./techs/sketch.svg',
	'./techs/sql_server.svg',
	'./techs/swift.svg',
	'./techs/vue_hs.svg',
];

export const cardsCities = [
	{
		city: 'Porto Alegre',
		image: './images/poa.png'
	},
	{
		city: 'São Paulo',
		image: './images/sp.png'
	},
	{
		city: 'Los Angeles',
		image: './images/la.png'
	}
]