import _ from '../../utils/lodash';
import styled, { css } from 'styled-components';

import BackdropFilter from 'react-backdrop-filter';

export const Wrapper = styled(BackdropFilter)`
	${({
		theme
	}) => css`
		font-family: ${_.get(theme, 'font.family.montserrat', 'Montserrat')};
	`}

	display: none;

	width: 100%;
	height: 100vh;
	padding: 18px 35px;
    z-index: 50;

	position: fixed;
	top: 0;
	bottom: 0;
	left: ${({ open }) => open ? '0px' : '-627px'};
	
	transition: all 500ms ease;

	& > div:last-child {
		flex: 1;
	}

	@media(max-width: 627px) {
		display: flex;
		flex-direction: column;

		& > div:last-child {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}
	}
`;

export const List = styled.ul`
	display: flex;
	flex-direction: column;
	align-items: center;
	flex: 1;

	margin-top: 66px;

	& li:last-child {
		margin-bottom: 0;
	}

	@media(max-width: 452px) {
		margin-top: 30px;
	}
`;

export const Header = styled.div`
	display: flex;
`;

export const ListItem = styled.li`
    ${({
	theme
}) => css`
		font-size: ${_.get(theme, 'font.family.size.h5', '32')}px;

		a {
			color: ${_.get(theme, 'colors.yellow', '#FBFF4A')};
			text-decoration: none;

			:hover {
				text-shadow: 0px 0px 15px ${_.get(theme, 'colors.yellow', '#FBFF4A')};
			}
		}
	`}

	display: flex;
    align-items: center;
		
	margin-bottom: 24px;
	height: 60px;

	font-weight: bold;
	line-height: 40px;
	text-transform: uppercase;
	white-space: nowrap;
	list-style: none;

	@media(max-width: 452px) {
		font-size: 20px;
		line-height: 24.38px;
		height: 36px;
		margin-bottom: 12px;
	}
`;

export const Footer = styled.div`
	${({
    	theme
	}) => css`
    	color: ${_.get(theme, 'colors.yellow', '#FBFF4A')};
        font-family: ${_.get(theme, 'font.family.montserrat', 'Montserrat')};
        font-size: ${_.get(theme, 'font.size.xs', '12')}px;
	`}

	line-height: 24px;
	flex: 1;
`;

export const Buttons = styled.div`
    display: flex;
	justify-content: center;

    margin-bottom: 48px;
	margin-top: 72px;

	& > button {
		background: none;
    	border: none;
		margin-right: 19px;

		& > a > div {
			display: flex;
			align-items: center;
			justify-content: center;
			border: 1px solid #FBFF4A;
			border-radius: 50%;
			height: 48px;
			width: 48px;

			& > svg {
				width: 18px;
				height: 18px;
			}
		}
	}

	& > button:last-child {
		margin-right: 0;
	}

	@media(max-width: 452px) {
		margin-bottom: 36px;
		margin-top: 34px;
	}
`;

export const Address = styled.p`
    margin-bottom: 12px;
	text-align: center;
`;

export const Email = styled.p`
    font-weight: bold;
	text-align: center;
`;

export const Whatsapp = styled.p`
    font-weight: bold;
	text-align: center;
`;

export const ButtonChangeLanguage = styled.button`
	background: none;
	border: none;
	cursor: pointer;
	margin-top: 12px;
`;