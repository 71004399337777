import React, { useEffect, useState } from 'react';
import useWindowSize from 'src/utils/hooks/useWindowSize';
import { useTranslation } from 'react-i18next';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/autoplay';

import { HeaderSection } from '../../HeaderSection';

import { imagesCases } from 'src/utils/data';

import { Wrapper, Image, ImageAstro } from './Cases.styles';

export const Cases: React.FC = () => {
	const size = useWindowSize();
	const { t } = useTranslation();

	const [resolution, setResolution] = useState(null);

	const headerCases = {
		title: t('headerCases.title'),
		description: t('headerCases.description'),
		color: 'orange_dark',
	};

	useEffect(() => {
		setResolution(size.width);
	}, [size]);

	return (
		<Wrapper>
			<div>
				<ImageAstro
					src={'./images/astro_cases.png'}
					alt="Astronauta"
					width='447px'
					height='502px'
				/>

				<HeaderSection data={headerCases} />

				<Swiper
					modules={[Autoplay]}
					spaceBetween={
						resolution <= 480 ? 15
							: resolution <= 768 ? 15
								: resolution <= 960 ? 18
									: resolution <= 1280 ? 18
										: 18
					}
					slidesPerView={
						resolution <= 452 ? 2
							: resolution <= 480 ? 3
								: resolution <= 762 ? 3
									: resolution <= 768 ? 5
										: resolution <= 830 ? 4
											: resolution <= 960 ? 5
												: resolution <= 1160 ? 5
													: resolution <= 1280 ? 7
														: 9
					}
					autoplay={{ delay: 0 }}
					loop
					loopedSlides={9}
					speed={2000}
				>
					{
						imagesCases.map((image, index) => {
							return (
								<SwiperSlide key={index} >
									<Image src={image} width={138} height={60} />
								</SwiperSlide>
							);
						})
					}
				</Swiper>
			</div>
		</Wrapper>
	);
};
