import React from 'react';
import { useTranslation } from 'react-i18next';

import { CardCity } from 'src/components/CardCity';
import { cardsCities } from 'src/utils/data';

import { Wrapper, Title, Map, Subtitle, Cards } from './Cities.styles';

export const Cities: React.FC = () => {
	const { t } = useTranslation();

	return (
		<Wrapper id="cities">
			<Title>{t('cities.title')}</Title>
			<Map src={'./images/mapa.svg'} />
			<Subtitle>{t('cities.subtitle')}</Subtitle>
			<Cards>
				{
					cardsCities.map((card, index) => {
						return (
							<CardCity
								key={index}
								image={card.image}
								city={card.city}
							/>
						)
					})
				}
			</Cards>
		</Wrapper>
	);
};
