import _ from '../../../utils/lodash';
import styled, { css} from 'styled-components';

export const Wrapper = styled.div`
    ${({
        theme
    }) => css`
    	color: ${_.get(theme, 'colors.orange_dark', '#662500')};
        font-family: ${_.get(theme, 'font.family.montserrat', 'Montserrat')};
	`}

    display: flex;
	flex-direction: column;
    align-items: center;

    padding: 288px 261px 0 261px;
    margin-bottom: 36px;
    margin-top: 170px;
    
    position: relative;
    overflow: visible;

    & > div {
        background: linear-gradient(180deg, #FF6C2D 0%, #FBFF4A 150.49%);
        position: relative;
        height: 612px;
        width: 1398px;
        border-radius: 20px;
        padding-top: 180px;

        & > div:first-child {
            & > div:last-child {
                max-width: 1024px;
            }
        }

        & > div:last-child {
            z-index: 5;
        }
    }

    @media(max-width: 1420px) {
        padding: 288px 76px 18px 76px;

        & > div {
            max-width: 1128px;
        }
    }

    @media(max-width: 1160px) {
        padding: 288px 78px 18px 78px;

        & > div {
            width: 804px;
            height: 672px;

            & > div:nth-child(2) {
                & > div:last-child {
                    max-width: 648px;
                }
            }
        }
	}

    @media(max-width: 830px) {
        padding: 288px 16px 18px 16px;

        & > div {
            width: 736px;
            height: 672px;

            & > div:nth-child(2) {
                & > div:last-child {
                    max-width: 648px;
                }
            }
        }
    }

    @media(max-width: 762px) {
        padding: 288px 16px 18px 16px;

        & > div {
            width: 448px;
            height: 816px;

            & > div:nth-child(2) {
                & > div:last-child {
                    max-width: 338px;
                }
            }
        }
    }

    @media(max-width: 452px) {
        & > div {
            width: 328px;

            & > div:nth-child(2) {
                & > div:first-child {
                    font-size: 42px;
                }

                & > div:last-child {
                    max-width: 258px;
                }
            }
        }
    }

`;

export const Image = styled.img`
    margin-top: 54px;
`;

export const ImageAstro = styled.img`
    position: absolute;
    top: -35%;
    left: 50%;
    transform: translateX(-50%) translateY(-10%);
    
    z-index: 8;
    width: 374px;
    height: 420px;

    @media(max-width: 768px) {
        top: -25%;
    }

    @media(max-width: 768px) {
        height: 368px;
        width: 328px;
    }
`;

export const ImageLeft = styled.img`
    position: absolute;
    top: 178px;
    opacity: 0.8;
    z-index: 1;
`;

export const ImageRight = styled.img`
    position: absolute;
    right: 0px;
    top: 0px;
    opacity: 0.8;
    z-index: 1;
`;