import _ from '../../utils/lodash';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    ${({
    theme,
    type,
}) => css`
        color: ${type === 'apps' ?
        _.get(theme, 'colors.yellow', '#FBFF4A') : type === 'sites' ?
            _.get(theme, 'colors.yellow_2', '#FFC267') : type === 'systems' ?
                _.get(theme, 'colors.pink', '#FC9287') : type === 'design' ?
                    _.get(theme, 'colors.pink_2', '#FC5C4F') :
                    _.get(theme, 'colors.yellow', '#FBFF4A')
    };
        font-family: ${_.get(theme, 'font.family.montserrat', 'Montserrat')};
        border-color: ${type === 'apps' ?
        _.get(theme, 'colors.yellow_dark', '#55570F') : type === 'sites' ?
            _.get(theme, 'colors.yellow_2_dark', '#6B4F26') : type === 'systems' ?
                _.get(theme, 'colors.pink_dark', '#84514B') : type === 'design' ?
                    _.get(theme, 'colors.pink_2_dark', '#822821') :
                    _.get(theme, 'colors.yellow_dark', '#55570F')
    };
    `}
        
    display: flex;
    flex-direction: column;
    justify-content: end;
        
    background-color: #1C2036;
    height: 100%;
    width: 100%;
    padding: 24px 35px;
    margin-right: 18px;
    margin-top: 12px; 
    border-radius: 5px;
    border-width: 1px;
    border-style: solid;

    position: relative;

    z-index: 3;

    & > div:first-child {
        display: none;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        background: linear-gradient(transparent, #1C2036);
        z-index: 3;
    }

    & > img {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        opacity: 0.1;
        object-fit: cover;
    }

    @media (max-width: 480px) {
        padding: 24px;
        padding-right: 41px;
    }

    :hover {
        & > div:first-child {
            display: flex;
        }

        & > img {
            opacity: 1;
        }
    }
`;

export const Title = styled.div`
    ${({
    theme
}) => css`
		font-size: ${_.get(theme, 'font.size.sm', '14')}px;
    `}

    line-height: 24px;
    text-transform: uppercase;

    z-index: 3;
`;

export const Subtitle = styled.div`
    ${({
    theme
}) => css`
		font-size: ${_.get(theme, 'font.size.sm', '14')}px;
    `}

    line-height: 24px;
    font-weight: 700;

    z-index: 3;
`;
