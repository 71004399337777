import React, { useEffect, useState } from 'react';
import useWindowSize from 'src/utils/hooks/useWindowSize';
import { useTranslation } from 'react-i18next';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/autoplay';

import CountUp from 'react-countup';

import { HeaderSection } from '../../HeaderSection';

import { imagesTechs } from 'src/utils/data';

import {
	Wrapper, 
	Image, 
	Numbers,
	Item, 
	Icon, 
	Number, 
	Row, 
	Title,
} from './Techs.styles';

export const Techs: React.FC = () => {
	const size = useWindowSize();
	const { t } = useTranslation();
	
	const [resolution, setResolution] = useState(null);

	useEffect(() => {
		setResolution(size.width);
	}, [size]);

	const headerTech = {
		title: t('headerTech.title'),
		description: t('headerTech.description'),
		color: 'orange',
	};

	return (
		<Wrapper>
			<HeaderSection data={headerTech} />

			<Swiper
				modules={[Autoplay]}
				spaceBetween={
					resolution <= 480 ? 24
					: resolution <= 768 ? 18
					: resolution <= 960 ? 18
					: resolution <= 1280 ? 18
					: 20
				}
				slidesPerView={
					resolution <= 480 ? 3
					: resolution <= 768 ? 5
					: resolution <= 960 ? 6
					: resolution <= 1280 ? 8
					: 12
				}
				autoplay={{ delay: 0 }}
				loop
				loopedSlides={9}
				speed={2000}
			>
				{
					imagesTechs.map((image, index) => {
						return (
							<SwiperSlide key={index} >
								<Image src={image} width={99.86} height={47.69} />
							</SwiperSlide>
						);
					})
				}
			</Swiper>

			<div>
				<img src={'./images/ufo.jpg'} width='590px' height='900px'  alt='Imagem' />
				<Numbers>
					<Row>
						<Item>
							<Icon src={'./icons/numeros_projetos.svg'}></Icon>
							<Number>
								<CountUp duration={3} end={220} suffix='+' />
							</Number>
							<Title>{t('numbersTech.projects')}</Title>
						</Item>

						<Item>
							<Icon src={'./icons/numeros_clientes_atendidos.svg'}></Icon>
							<Number>
								<CountUp duration={3} end={100} suffix='+' />
							</Number>
							<Title>{t('numbersTech.clients')}</Title>
						</Item>
					</Row>

					<Row>
						<Item>
							<Icon src={'./icons/numeros_cidades.svg'}></Icon>
							<Number>
								<CountUp duration={3} end={15} suffix='+' />
							</Number>
							<Title>{t('numbersTech.cities')}</Title>
						</Item>

						<Item>
							<Icon src={'./icons/numeros_papel.svg'}></Icon>
							<Number>0</Number>
							<Title>{t('numbersTech.paper')}</Title>
						</Item>
					</Row>
				</Numbers>

				<Numbers>
					<Row>
						<Item>
							<Icon src={'./icons/numeros_profissionais.svg'}></Icon>
							<Number>
								<CountUp duration={3} end={10000} suffix='+' />
							</Number>
							<Title>{t('numbersTech.professionals')}</Title>
						</Item>

						<Item>
							<Icon src={'./icons/numeros_horas.svg'}></Icon>
							<Number>
								<CountUp duration={3} end={9000} suffix='+' />
							</Number>
							<Title>{t('numbersTech.hours')}</Title>
						</Item>
					</Row>

					<Row>
						<Item>
							<Icon src={'./icons/numeros_cafe.svg'}></Icon>
							<Number>
								<CountUp duration={3} end={300} suffix='+' />
							</Number>
							<Title>{t('numbersTech.cups')}</Title>
						</Item>

						<Item>
							<Icon src={'./icons/numeros_tecnicas.svg'}></Icon>
							<Number>
								<CountUp duration={3} end={250} suffix='+' />
							</Number>
							<Title>{t('numbersTech.techs')}</Title>
						</Item>
					</Row>
				</Numbers>
			</div>
		</Wrapper>
	);
};
