import _ from '../../../utils/lodash';
import styled, { css} from 'styled-components';

export const Wrapper = styled.div`
    ${({
        theme
    }) => css`
    	color: ${_.get(theme, 'colors.yellow', '#FBFF4A')};
        font-family: ${_.get(theme, 'font.family.montserrat', 'Montserrat')};
	`}

    display: flex;
	flex-direction: column;
    align-items: center;

	padding: 0px 24px;
    padding-top: 96px;

    position: relative;

    @media(max-width: 768px) {
        padding: 0px 16px;   
        padding-top: 96px;
    }
`;

export const Title = styled.div`
    ${({
        theme
    }) => css`
        font-size: ${_.get(theme, 'font.size.md', '16')}px;
	`}

    font-weight: bold;
    line-height: 24px;
    text-align: center;
    height: 36px;

    @media(max-width: 887px) {
        height: 72px;   
    }
`;

export const Subtitle = styled.div`
    ${({
        theme
    }) => css`
        font-size: ${_.get(theme, 'font.size.h6', '24')}px;
	`}

    font-weight: bold;
    line-height: 24px;
    text-align: center;
    height: 36px;

    @media(max-width: 887px) {
        height: 72px;   
    }
`;

export const Description = styled.div`
    ${({
        theme
    }) => css`
        font-size: ${_.get(theme, 'font.size.sm', '14')}px;
	`}

    display: flex;
    align-items: baseline;

    margin-top: 24px;

    line-height: 30px;
    text-align: center;
`;

export const Cards = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    margin-top: 72px;
    z-index: 3;

    & > div {
        margin-right: 24px;
    }

    & > div:last-child {
        margin-right: 0px;
    }

    @media(max-width: 752px) {
        margin-top: 34px;
        flex-direction: column;
    }

    @media(max-width: 768px) {
        & > div {
            margin-right: 18px;
        }
    }

    @media(max-width: 480px) {
        & > div {
            margin-right: 0px;
        }
    }
`;

export const Map = styled.img`
    height: 312px;
    width: 766px;

    margin-top: 120px;
    margin-bottom: 24px;

    @media(max-width: 768px) {
        height: 300px;
        width: 736px;
    }

    @media(max-width: 480px) {
        height: 195.51px;
        width: 480px;
    }

    @media(max-width: 452px) {
        height: 142px;
        width: 348.53px;
    }
`;