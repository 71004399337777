import React from 'react';

import { Wrapper, WrapperImage, Image, Description, InfoClient, Company } from './CardClient.styles';
import { CardClientProps } from './CardClient.types';

export const CardClient: React.FC<CardClientProps> = props => {
	const { description, companyName, companyLogo } = props;

	return (
		<Wrapper>
			<WrapperImage>
				<div />
				<Image src={'./icons/clientes_quote.svg'} />
			</WrapperImage>

			<div>
				<Description>{description}</Description>
				<InfoClient>
					<Company>
						<img src={companyLogo} alt={`Logo ${companyName}`} />
					</Company>
				</InfoClient>
			</div>
		</Wrapper>
	);
};
