import React from 'react';

import {
	Wrapper,
	Title,
	Subtitle,
	Description,
} from './HeaderSection.styles';

import { HeaderSectionProps } from './HeaderSection.types';

export const HeaderSection: React.FC<HeaderSectionProps> = props => {
	const { data } = props;
	const { color, title, subtitle, description, nowrap } = data;

	return (
		<Wrapper color={color}>
			<Title nowrap={nowrap}>{title}</Title>

			{subtitle && <Subtitle>{subtitle}</Subtitle>}

			{description && <Description>{description}</Description>}
		</Wrapper>
	);
};
