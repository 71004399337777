import _ from '../../../utils/lodash';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    ${({
    theme
}) => css`
    	color: ${_.get(theme, 'colors.cyan', '#68E1D5')};
        font-family: ${_.get(theme, 'font.family.montserrat', 'Montserrat')};
	`}

    display: flex;
	flex-direction: column;
    align-items: center;

	padding: 0px 24px;
    padding-top: 180px;
    margin-top: 447px;

    position: relative;

    & > img {
        position: absolute;
        top: 850px;
        right: 0;
        z-index: 1;
    }

    @media(max-width: 1280px) {
        margin-top: 447px;
    }

    @media(max-width: 960px) {
        margin-top: 347px;
	}

    @media(max-width: 768px) {
        margin-top: 266px;
    }

    @media(max-width: 480px) {
        margin-top: 195px;
    }

    @media(max-width: 452px) {
        margin-top: 75px;
    }
`;

export const Infos = styled.div`
    display: flex;
    margin-top: 120px;
    z-index: 5;

    @media(max-width: 897px) {
        justify-content: space-between;

        & > div:last-child > div:last-child {
            margin-bottom: 30px;
        }
    }

    @media(max-width: 710px) {
        flex-direction: column;

        & > div:first-child {
            margin-top: 0;
        }
    }

    @media(max-width: 480px) {
        & > div:nth-child(3) > div:last-child {
            margin-bottom: 0px;
        }
    }
`;

export const Info = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    max-width: 608px;
    margin-right: 18px;
    flex: 1;

    @media(max-width: 1855px) {
        max-width: 392px;
    }

    @media(max-width: 1250px) {
        max-width: 282px;
    }

    @media(max-width: 960px) {
        max-width: 268px;
    }

    @media(max-width: 925px) {
        max-width: 180px;
    }

    @media(max-width: 897px) {
        justify-content: flex-start;
    }

    @media(max-width: 768px) {
        max-width: 180px;
    }

    @media(max-width: 711px) {
        max-width: 488px;
        margin-top: 36px;
    }
`;

export const Title = styled.div`
    ${({
    theme
}) => css`
        font-size: ${_.get(theme, 'font.size.h7', '22')}px;
	`}

    font-weight: bold;
    line-height: 36px;
    text-align: center;

    @media(max-width: 887px) {
        height: 72px;   
    }
`;

export const Description = styled.div`
    ${({
    theme
}) => css`
        font-size: ${_.get(theme, 'font.size.sm', '14')}px;
	`}

    display: flex;
    align-items: baseline;

    margin-top: 24px;

    line-height: 30px;
    text-align: center;
`;

export const Cards = styled.div`
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(2, 750px);
    grid-template-areas: "card1 card1 card2 card2 card3 card3"
                         "empt card4 card4 card5 card5 emp2";

    grid-gap: 48px 24px; 

    & > div:nth-child(1) {
        grid-area: card1;
    }
    
    & > div:nth-child(2) {
        grid-area: card2;
    }
    
    & > div:nth-child(3) {
        grid-area: card3;
    }
    
    & > div:nth-child(4) {
        grid-area: card4;
    }
    
    & > div:nth-child(5) {
        grid-area: card5;
    }
    
    margin-top: 180px;
    margin-bottom: 180px;
    z-index: 3;

    @media(max-width: 1855px) {
        grid-template-rows: repeat(2, 780px);
        grid-gap: 48px 18px; 
    }

    @media(max-width: 1250px) {
        grid-template-rows: repeat(2, 741px);
        grid-gap: 48px 18px; 
    }
    
    @media(max-width: 960px) {
        grid-template-rows: repeat(2, 741px);
        grid-gap: 48px 18px; 
    }
    
    @media(max-width: 925px) {
        grid-gap: 48px 18px; 
        margin-top: 120px;

        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(3, 809px);
        grid-template-areas: "card1 card1 card2 card2"
                            "card3 card3 card4 card4 "
                            "empt card5 card5 emp2";
    }

    @media(max-width: 768px) {
        grid-template-rows: repeat(2, 813px);
        grid-gap: 48px 18px; 
    }

    @media(max-width: 751px) {
        grid-template-rows: repeat(2, 839px);
        grid-gap: 45px 24px; 
        margin-top: 96px;
    }

    @media(max-width: 627px) {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(5, 681px);
        grid-template-areas: "card1"
                            "card2"
                            "card3"
                            "card4"
                            "card5";
        grid-gap: 45px 24px; 
    }

    @media(max-width: 550px) {
        grid-template-rows: repeat(5, 665px);
    }

    @media(max-width: 435px) {
        grid-template-rows: repeat(5, 695px);
    }

    @media (max-width: 360px) {
        grid-template-rows: repeat(5, 749px);
    }
`;