import React from 'react';

import { Wrapper, Title, Subtitle } from './CardExpertise.styles';
import { CardExpertiseProps } from './CardExpertise.types';

export const CardExpertise: React.FC<CardExpertiseProps> = props => {
	const { title, subtitle, image, type } = props;

	return (
		<Wrapper type={type}>
			<div />
			{title && <Title>| {title}</Title>}
			<Subtitle>{subtitle}</Subtitle>
			<img src={image} alt="Imagem Card Expertise"/>
		</Wrapper>
	);
};
