import React, { useEffect, useState } from 'react';
import useWindowSize from 'src/utils/hooks/useWindowSize';
import { useTranslation } from 'react-i18next';

import { AccordionDetails } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import { CardExpertise } from '../../CardExpertise';
import { HeaderSection } from '../../HeaderSection';

import {
	Wrapper,
	Cards,
	Link,
	Accordions,
	AccordionStyled,
	CardsAccordion,
	AccordionSummaryStyled,
	CardsAccordionSystems,
} from './Expertise.styles';

export const Expertise: React.FC = () => {
	const size = useWindowSize();
	const { t } = useTranslation();

	const [resolution, setResolution] = useState(null);

	useEffect(() => {
		setResolution(size.width);
	}, [size]);

	const headerExpertise = {
		title: t('headerExpertise.title'),
		subtitle: t('headerExpertise.subtitle'),
		description: t('headerExpertise.description'),
		nowrap: true
	};

	return (
		<Wrapper id="expertise">
			<HeaderSection data={headerExpertise} />

			{
				resolution > 605 && (
					<Cards>
						<div>
							<CardExpertise
								type='apps'
								title={t('cardsExpertise.mobileApps.title')}
								image={`./expertise/apps_commerce.png`}
								subtitle={t('cardsExpertise.mobileApps.commerce')}
							/>
							<CardExpertise
								type='apps'
								title={t('cardsExpertise.mobileApps.title')}
								image={`./expertise/apps_delivery.png`}
								subtitle={t('cardsExpertise.mobileApps.delivery')}
							/>
							<CardExpertise
								type='apps'
								title={t('cardsExpertise.mobileApps.title')}
								image={`./expertise/apps_custom.png`}
								subtitle={t('cardsExpertise.mobileApps.customized')}
							/>
							<CardExpertise
								type='sites'
								title={t('cardsExpertise.websites.title')}
								image={`./expertise/sites_commerce.png`}
								subtitle={t('cardsExpertise.websites.portals')}
							/>
							<CardExpertise
								type='sites'
								title={t('cardsExpertise.websites.title')}
								image={`./expertise/sites_cms.png`}
								subtitle={t('cardsExpertise.websites.cms')}
							/>
							<CardExpertise
								type='sites'
								title={t('cardsExpertise.websites.title')}
								image={`./expertise/sites_inst.png`}
								subtitle={t('cardsExpertise.websites.institutional')}
							/>
						</div>
						<div>
							<CardExpertise
								type='systems'
								title={t('cardsExpertise.systems.title')}
								image={`./expertise/sist_gerenciais.png`}
								subtitle={t('cardsExpertise.systems.management')}
							/>
							<CardExpertise
								type='systems'
								title={t('cardsExpertise.systems.title')}
								image={`./expertise/sist_integr.png`}
								subtitle={t('cardsExpertise.systems.systemic')}
							/>
							<CardExpertise
								type='systems'
								title={t('cardsExpertise.systems.title')}
								image={`./expertise/sist_plataf.png`}
								subtitle={t('cardsExpertise.systems.service')}
							/>
							<CardExpertise
								type='systems'
								title={t('cardsExpertise.systems.title')}
								image={`./expertise/sist_b2bb2c.png`}
								subtitle={t('cardsExpertise.systems.digitalSolutions')}
							/>
							<CardExpertise
								type='systems'
								title={t('cardsExpertise.systems.title')}
								image={`./expertise/sist_infra_cloud.png`}
								subtitle={t('cardsExpertise.systems.infrastructure')}
							/>
						</div>
						<div>
							<CardExpertise
								type='design'
								title={t('cardsExpertise.design.title')}
								image={`./expertise/design_ident_visual.png`}
								subtitle={t('cardsExpertise.design.visual')}
							/>
							<CardExpertise
								type='design'
								title={t('cardsExpertise.design.title')}
								image={`./expertise/design_branding.png`}
								subtitle={t('cardsExpertise.design.branding')}
							/>
							<CardExpertise
								type='design'
								title={t('cardsExpertise.design.title')}
								image={`./expertise/design_midias.png`}
								subtitle={t('cardsExpertise.design.media')}
							/>
						</div>
						<Link href='#form'>
							<p>{t('linkExpertise.firstPart')}</p>
							<p>{t('linkExpertise.secondPart')}</p>
							<div>
								<img src={'./icons/ico_right.svg'} alt='Ícone seta para direita' />
								<img src={'./icons/coffee.svg'} alt='Ícone café' />
							</div>
						</Link>
					</Cards>
				)
			}

			{
				resolution <= 605 && (
					<Accordions>
						<AccordionStyled type='apps'>
							<AccordionSummaryStyled
								expandIcon={<ArrowDownwardIcon />}
								aria-controls="panel1a-content"
								id="panel1a-header"
							>
								<span>{t('cardsExpertise.mobileApps.title')}</span>
							</AccordionSummaryStyled>
							<AccordionDetails>
								<CardsAccordion>
									<CardExpertise
										type='apps'
										subtitle={t('cardsExpertise.mobileApps.commerce')}
										image={`./expertise/apps_commerce.png`}
									/>
									<CardExpertise
										type='apps'
										subtitle={t('cardsExpertise.mobileApps.delivery')}
										image={`./expertise/apps_delivery.png`}
									/>
									<CardExpertise
										type='apps'
										subtitle={t('cardsExpertise.mobileApps.customized')}
										image={`./expertise/apps_custom.png`}
									/>
								</CardsAccordion>
							</AccordionDetails>
						</AccordionStyled>

						<AccordionStyled type='sites'>
							<AccordionSummaryStyled
								expandIcon={<ArrowDownwardIcon />}
								aria-controls="panel1a-content"
								id="panel1a-header"
							>
								<span>{t('cardsExpertise.websites.title')}</span>
							</AccordionSummaryStyled>
							<AccordionDetails>
								<CardsAccordion>
									<CardExpertise
										type='sites'
										image={`./expertise/sites_commerce.png`}
										subtitle={t('cardsExpertise.websites.portals')}
									/>
									<CardExpertise
										type='sites'
										image={`./expertise/sites_cms.png`}
										subtitle={t('cardsExpertise.websites.cms')}
									/>
									<CardExpertise
										type='sites'
										image={`./expertise/sites_inst.png`}
										subtitle={t('cardsExpertise.websites.institutional')}
									/>
								</CardsAccordion>
							</AccordionDetails>
						</AccordionStyled>

						<AccordionStyled type='systems'>
							<AccordionSummaryStyled
								expandIcon={<ArrowDownwardIcon />}
								aria-controls="panel1a-content"
								id="panel1a-header"
							>
								<span>{t('cardsExpertise.systems.title')}</span>
							</AccordionSummaryStyled>
							<AccordionDetails>
								<CardsAccordionSystems>
									<CardExpertise
										type='systems'
										image={`./expertise/sist_gerenciais.png`}
										subtitle={t('cardsExpertise.systems.management')}
									/>
									<CardExpertise
										type='systems'
										image={`./expertise/sist_integr.png`}
										subtitle={t('cardsExpertise.systems.systemic')}
									/>
									<CardExpertise
										type='systems'
										image={`./expertise/sist_plataf.png`}
										subtitle={t('cardsExpertise.systems.service')}
									/>
									<CardExpertise
										type='systems'
										image={`./expertise/sist_b2bb2c.png`}
										subtitle={t('cardsExpertise.systems.digitalSolutions')}
									/>
									<CardExpertise
										type='systems'
										image={`./expertise/sist_infra_cloud.png`}
										subtitle={t('cardsExpertise.systems.infrastructure')}
									/>
								</CardsAccordionSystems>
							</AccordionDetails>
						</AccordionStyled>

						<AccordionStyled type='design'>
							<AccordionSummaryStyled
								expandIcon={<ArrowDownwardIcon />}
								aria-controls="panel1a-content"
								id="panel1a-header"
							>
								<span>{t('cardsExpertise.design.title')}</span>
							</AccordionSummaryStyled>
							<AccordionDetails>
								<CardsAccordion>
									<CardExpertise
										type='design'
										image={`./expertise/design_ident_visual.png`}
										subtitle={t('cardsExpertise.design.visual')}
									/>
									<CardExpertise
										type='design'
										image={`./expertise/design_branding.png`}
										subtitle={t('cardsExpertise.design.branding')}
									/>
									<CardExpertise
										type='design'
										image={`./expertise/design_midias.png`}
										subtitle={t('cardsExpertise.design.media')}
									/>
								</CardsAccordion>
							</AccordionDetails>
						</AccordionStyled>

						<Link href='#form'>
							<p>{t('linkExpertise.firstPart')}</p>
							<p>{t('linkExpertise.secondPart')}</p>
							<div>
								<img src={'./icons/ico_right.svg'} alt='Ícone seta para direita' />
								<img src={'./icons/coffee.svg'} alt='Ícone café' />
							</div>
						</Link>
					</Accordions>
				)
			}
		</Wrapper>
	);
};
