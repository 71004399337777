import styled from 'styled-components';

export const Landing = styled.div`
  background: url('./images/bg_modelo.jpg');
  width: 100vw;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
  position: absolute;
  bottom: 0;
  top: 0;
  height: auto;
  padding-top: 70px;

  @media(max-width: 768px) {
    padding-top: 76px;
  }
`;

export const Circular = styled.div`
    position: absolute;
    width: 650px;
    height: 650px;
    border-radius: 410px;
    z-index: 2;
`;

export const CircularHero = styled(Circular)`
    right: -624px;
    top: 86px;
    background: #FF4D77;
    filter: blur(247px);

    @media(max-width: 1280px) {
      left: 1222px;
    }

    @media(max-width: 768px) {
      left: 752px;
    }

    @media(max-width: 480px) {
      left: 464px;
      top: 60px;
    }
`;

export const CircularExpertiseLeft = styled(Circular)`
    left: -626px;
    top: 832px;
    background: #FBFF4A;
    filter: blur(138px);
    
    @media(max-width: 768px) {
      left: -634px;
    }

    @media(max-width: 480px) {
      left: -641px;
      top: 458px;
      filter: blur(92px);
    }

    @media(max-width: 452px) {
      top: 583px;
      width: 596px;
      height: 593px;
    }
`;

export const CircularExpertiseRight = styled(Circular)`
    top: 1989px;
    left: 1893px;
    background: #FBFF4A;
    filter: blur(263px);

    @media(max-width: 1280px) {
      left: 1222px;
    }

    @media(max-width: 960px) {
      left: 936px;
    }

    @media(max-width: 768px) {
      top: 2388px;
      left: 752px;
    }

    @media(max-width: 480px) {
      left: 464px;
      top: 1505px;
      width: 458px;
      height: 450px;
    }
`;

export const CircularAboutLeftCyan = styled(Circular)`
    top: 3034px;
    left: -547px;
    background: #68E1D5;
    filter: blur(368px);

    @media(max-width: 768px) {
      top: 3238px;
      left: -634px;
    }

    @media(max-width: 480px) {
      top: 2166px;
      left: -645px;
      filter: blur(106px);
    }
`;

export const CircularAboutLeftBlue = styled(Circular)`
    top: 4738px;
    left: -547px;
    background: #00D1FF;
    filter: blur(247px);

    @media(max-width: 768px) {
      left: -634px;
      top: 4646px;
    }

    @media(max-width: 480px) {
      top: 5124px;
      left: -625px;
      filter: blur(100px);
    }
`;

export const CircularAboutRight = styled(Circular)`
    top: 4034px;
    left: 1817px;
    background: #FF4D77;
    filter: blur(247px);

    @media(max-width: 1280px) {
      left: 1222px;
    }
    
    @media(max-width: 960px) {
      left: 936px;
    }

    @media(max-width: 768px) {
      left: 752px;
    }

    @media(max-width: 480px) {
      top: 3309px;
      left: 464px;
    }
`;

export const CircularCases = styled(Circular)`
    top: 7582px;
    left: -547px;
    background: #FF6C2D;
    filter: blur(247px);

    @media(max-width: 960px) {
      left: -626px;
    }

    @media(max-width: 768px) {
      left: -634px;
      top: 7722px;
    }

    @media(max-width: 480px) {
      top: 8562px;
      left: -434px;
      filter: blur(328px);
    }

    @media(max-width: 452px) {
      top: 11003px;
    }
`;

export const CircularTech = styled(Circular)`
    top: 5760px;
    left: 1817px;
    background: #FF6C2D;
    filter: blur(247px);

    @media(max-width: 1280px) {
      left: 1222px;
    }

    @media(max-width: 960px) {
      left: 936px;
    }

    @media(max-width: 768px) {
      left: 752px;
    }

    @media(max-width: 480px) {
      top: 6000px;
      left: 464px;
    }

    @media(max-width: 452px) {
      top: 8309px;
    }
`;

export const CircularForm = styled(Circular)`
    top: 8518px;
    left: 1817px;
    background: #FBFF4A;
    filter: blur(247px);

    @media(max-width: 1280px) {
      left: 1222px;
    }

    @media(max-width: 960px) {
      left: 936px;
    }

    @media(max-width: 768px) {
      left: 752px;
      top: 8644px;
    }

    @media(max-width: 480px) {
      top: 11988px;
      left: 464px;
      width: 550px;
      height: 555px;
      filter: blur(201px);
    }
`;

export const Image = styled.div`
  position: relative;

  & > img {
    position: absolute;
    top: -102px;
    left: 0;
    width: 1921px;
    height: 957px;
  }

  @media (min-width: 1921px) {
      & > img {
        width: 100%;
    }
  }

  @media (max-width: 1280px) {
      & > img {
        margin-top: 24px;
        top: -89px;
        left: -214px;
        height: 851px;
        width: 1708px;
      }
  }

  @media (max-width: 960px) {
      & > img {
        margin-top: 19px;
        top: -89px;
        left: -275px;
        height: 753px;
        width: 1512px;
      }
  }

  @media (max-width: 768px) {
      & > img {
        margin-top: 0px;
        position: absolute;
        top: -97px;
        height: 701px;
        width: 1408px;
        left: -319px;
      }
  }

  @media (max-width: 615px) {
      & > img {
        margin-top: 0px;
        top: -72px;
        left: -313px;
        height: 552px;
        width: 1108px;
      }
  }

  @media (max-width: 452px) {
      & > img {
        margin-top: 0px;
        top: -72px;
        height: 452px;
        width: 782px;
        left: -211px;
      }
  }
`;